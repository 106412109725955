<template>
  <v-container fluid class="mb-16">
    <v-card elevation="0" height="90vh">
      <v-row>
        <v-card-text>
          <v-row class="ml-7" style="width: 96%">
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #ab47bc">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Today's Order
                  <v-spacer></v-spacer>
                  <v-list text style="background: #ab47bc; color: white">{{
                    today_order
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #816bff">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Customers
                  <v-spacer></v-spacer>
                  <v-list text style="background: #816bff; color: white">{{
                    total_customer
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #40c4ff">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Seller
                  <v-spacer></v-spacer>
                  <v-list text style="background: #40c4ff; color: white">{{
                    total_seller
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card class="d-flex flex-column" style="background: #4dd0e1">
                <v-card-title
                  style="font-size: 20px; color: white"
                  class="pt-5 mb-2"
                >
                  Total Staff
                  <v-spacer></v-spacer>
                  <v-list text style="background: #4dd0e1; color: white">{{
                    total_staff
                  }}</v-list>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="ml-7" style="width: 96%">
            <v-col cols="3">
              <v-card
                style="background: #f5f5f5; overflow: auto; min-height: 94%"
                class="pb-0 mt-5"
              >
                <v-card-title class="pt-5 pb-0 pl-14">
                  Support Ticket
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-card-actions class="ml-2 mb-2">
                  <v-list three-line color="#f5f5f5" width="100%">
                    <template v-for="(item, index) in tickets">
                      <v-divider
                        v-if="item.divider"
                        :key="index"
                        :inset="item.inset"
                      ></v-divider>

                      <v-list-item
                        v-else
                        :key="item.sender_name"
                        @click="openchat(item)"
                        round
                      >
                        <v-avatar v-if="item.sender_name" color="indigo">
                          <span class="white--text">{{
                            item.sender_name.slice(0, 2)
                          }}</span>
                        </v-avatar>
                        <v-avatar v-else color="indigo">
                          <span class="white--text">Un</span>
                        </v-avatar>

                        <v-list-item-content class="pl-4 mt-3">
                          <v-list-item-title v-if="item.sender_name">
                            User Name: {{ item.sender_name }}
                          </v-list-item-title>
                          <v-list-item-title v-else>Unknown</v-list-item-title>
                          <v-list-item-subtitle>
                            User ID: {{ item.user_id }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card-actions>

                <!-- color="#816BFF" -->
                <v-btn
                  style="position: absolute; right: 0; bottom: 0px"
                  to="/dashboard/pages/supports"
                  color="#AB47BC"
                  elevation="0"
                  bottom
                  class="mx-0 mb-0"
                  block
                >
                  See More</v-btn
                >
                <!-- <v-card-actions style="position:absolute ; right:0;">
                  <v-spacer></v-spacer>
                  <v-btn to="/dashboard/pages/supports" color="primary" elevation="0" bottom> See More</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>

            <v-col cols="9">
              <v-card style="background: #f5f5f5">
                <v-row style="min-height: 100%">
                  <v-col cols="6">
                    <v-card-title class="pl-6">
                      Delivery Statistics
                    </v-card-title>
                    <v-card style="background: white" class="ml-6">
                      <v-card-actions class="ml-2">
                        <div id="chart">
                          <!-- width="380" -->
                          <!-- <apexchart
                            type="pie"
                           
                            :series="series"
                            :options="chartOptions"
                          ></apexchart> -->
                          <apexchart
                            type="pie"
                            width="380"
                            :options="chartOptions"
                            :series="series"
                            ref="deliveryChart"
                          ></apexchart>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card-title> Order Statistics </v-card-title>
                    <v-card
                      style="background: white"
                      class="mr-6 mb-0"
                      height="78%"
                    >
                      <v-card-actions class="ml-2">
                        <OrderChart />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <!-- <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-card>
                  <v-data-table
                    :headers="orderHeaders"
                    :items="recentOrders"
                    :search="search"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    no-data-text="No orders available"
                    item-key="id"
                    show-expand
                    class="elevation-1"
                    style="background: #f5f5f5"
                  >
                    <template v-slot:top>
                      <v-card-title style="font-size: 26px">
                        Recent Orders
                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-2">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            hide-details
                          ></v-text-field>
                        </div>
                      </v-card-title>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <b>Order Refrence: </b> {{ item.id }} <br />
                        <b>User ID : </b> {{ item.user_id }}<br />
                        <b
                          >Order Items :
                          <span
                            v-for="(product, index) in item.orders"
                            :key="index"
                            class="font-weight-medium"
                            >{{ product.product_name }} ,
                          </span></b
                        >
                        <br />
                        <b>Date : </b> {{ item.ordered_date }}<br />
                        <b>Host Site : </b> {{ item.host }}<br />
                        <b>Order Status : </b> {{ item.order_status }}<br />
                        <b>Delivery Type : </b> {{ item.delivery_status }}<br />
                        <b>Copun Code : </b> {{ item.coupon_code }}<br />
                        <b>Copun Percentage : </b> {{ item.coupon_percentage
                        }}<br />
                        <b>Total Point : </b> {{ item.point_total }}<br />
                      </td>
                    </template>
                    <template v-slot:item.adminaction="{ item }">
                      
                      <v-icon
                        color="primary"
                        class="pa-2 mx-4"
                        @click="Approve(item)"
                        >mdi-checkbox-marked-circle</v-icon
                      >
                      <v-icon
                        color="red"
                        class="pa-2"
                        @click="ApproveCancel(item)"
                        >mdi-close-circle</v-icon
                      >
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        color="primary"
                        style="padding-left: 0px !important"
                        text
                        @click="viewDetails(item)"
                        >Details</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </template>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-row>
    </v-card>
  </v-container>
</template>


<script>
export default {
  components: {
    OrderChart: () => import("./components/core/OrderChart"),
  },

  data: () => ({
    expanded: [],
    dialog: false,
    loading: true,
    selection: 1,
    search: "",
    editdialog: false,
    searchseller: "",

    singleExpand: false,

    orderHeaders: [
      {
        text: "Order ID",
        align: "start",
        value: "id",
      },
      { text: "Date", value: "ordered_date" },
      { text: "Price ", value: "price_total" },
      { text: "Payment Status", value: "order_status" },
      { text: "Delivery Status", value: "delivery_status" },
      { text: "Coupon Code", value: "coupon_code" },
      { text: "Coupon Percentage", value: "coupon_percentage" },
      { text: "Total Points", value: "point_total" },
      // { text: "Admin Confirmation", value: "adminaction", sortable: false },
      { text: "", value: "actions", sortable: false },
      // { text: 'test', value: 'tes' },
      { text: "", value: "data-table-expand" },
    ],

    showItem: {},

    tickets: [],

    contact: {},
    Logged_user: {},
    orders: [],

    recentOrders: [],
    tempticket: [],
    pendingarray: [],
    Cancelledarray: [],
    show: false,
    today_order: 0,
    total_customer: 0,
    total_seller: 0,
    total_staff: 0,
    admin_status: "",
    sellerPdetails: {},
    subticket: [],

    colorsslider: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],

    series: [],

    chartOptions: {
      chart: {
        width: 280,
        type: "pie",
        offsetX: 60,
      },
      labels: ["Delivered", "Pending", "Cancelled"],
      colors: ["#816BFF", "#13C9F2", "#F94B4B"],
      responsive: [
        {
          breakpoint: 1500,
          options: {
            chart: {
              width: "100%",
              height: "250px",
              offsetX: 0,
            },
            legend: {
              // position: "bottom",
              show: false,
            },
          },
        },

        {
          breakpoint: 1700,
          options: {
            chart: {
              width: "350px",
              height: "250px",
              offsetX: 0,
            },
            legend: {
              // position: "top",
              show: true,
            },
          },
        },
      ],

      legend: {
        position: "left",
        offsetY: 25,
        offsetX: -30,
        height: 630,
        itemMargin: {
          vertical: 20,
        },
      },
    },
  }),

  methods: {
    initialize() {
      // axios.get(`Cart/orders_pending/`).then((response) => {
      //   this.loading = false;
      //   if (response.data.success) {
      //     this.recentOrders = response.data.data;
      //   }
      // });

      axios.get("supports/dashboard/").then((response) => {
        this.today_order = response.data.data.orders;
        this.total_customer = response.data.data.total_customers;
        this.total_seller = response.data.data.total_sellers;
        this.total_staff = response.data.data.total_staff;
      });
    },

    // Delivery statistics
    getDeliveryChartInfo() {
      axios.get("supports/delivery_statistics/").then((response) => {
        if (response.data.success) {
          let {cancelled , delivered , pending } = response.data
          this.$refs.deliveryChart.updateOptions({
            series: [delivered, pending, cancelled],
          });
        }
      });
    },

    viewDetail(item) {
      this.sellerPdetails = item;
      this.dialog = true;
    },

    getreply() {
      axios.get("supports/ticket_list/").then((response) => {
        //this.tickets = response.data.data;
        this.tempticket = response.data.data;
        this.tickets = this.tempticket.slice(0, 3);
      });
    },
    openchat(item) {
      this.$router.push("/dashboard/pages/supports");
    },

    closedialog() {
      this.dialog = false;
    },
    viewDetails(item) {
      this.$router.push({
        path: "/dashboard/pages/confirmproduct",
        query: { id: item.id },
      });
    },
  },
  mounted() {
    this.initialize();
    this.getDeliveryChartInfo();
    this.getreply();
    this.Logged_user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>